<template>
    <section class="load-restaurant" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Доходы ресторана"</h2>
        <div class="load-restaurant__base" v-if="modeId === 'base'">
            <div class="load-restaurant__box load-restaurant__box_charts box">
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_first-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Заказов в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('orders_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_second-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Гостей в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('guests_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_third-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Блюд в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('dish_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_first-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Заказов на курьера</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('couriers_per_hour_avg')" />
                    </figure>
                </div>
            </div>
            <div class="load-restaurant__box load-restaurant__box_table box">
                <table class="load-restaurant__table">
                    <colgroup>
                        <col class="load-restaurant__table-column load-restaurant__table-column_title">
                        <col class="load-restaurant__table-column load-restaurant__table-column_income">
                        <col class="load-restaurant__table-column load-restaurant__table-column_orders-count">
                        <col class="load-restaurant__table-column load-restaurant__table-column_average-bill">
                    </colgroup>
                    <thead>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_head">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">&nbsp;</td>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Заказов на курьера</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                Доставка
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportRestaurant.delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                Зал
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportRestaurant.hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                С собой
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportRestaurant.take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                Самовынос
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportRestaurant.pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                Итого
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.total.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.total.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{reportRestaurant.total.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportRestaurant.total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="load-restaurant__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareRestaurant">
            <div class="load-restaurant__box load-restaurant__box_table box">
                <table class="load-restaurant__table">
                    <caption class="load-restaurant__table-caption">Заказов в час</caption>
                    <colgroup>
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_head">
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.delivery.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.delivery.orders_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.delivery.orders_per_hour_avg"
                                    :compareValue="compareRestaurant.delivery.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.hall.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.hall.orders_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.hall.orders_per_hour_avg"
                                    :compareValue="compareRestaurant.hall.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.take.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.take.orders_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.take.orders_per_hour_avg"
                                    :compareValue="compareRestaurant.take.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.pickup.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.pickup.orders_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.pickup.orders_per_hour_avg"
                                    :compareValue="compareRestaurant.pickup.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.total.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.total.orders_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.total.orders_per_hour_avg"
                                    :compareValue="compareRestaurant.total.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.total.orders_per_hour_avg | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-restaurant__box load-restaurant__box_table box">
                <table class="load-restaurant__table">
                    <caption class="load-restaurant__table-caption">Гостей в час</caption>
                    <colgroup>
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_head">
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.delivery.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.delivery.guests_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.delivery.guests_per_hour_avg"
                                    :compareValue="compareRestaurant.delivery.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.hall.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.hall.guests_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.hall.guests_per_hour_avg"
                                    :compareValue="compareRestaurant.hall.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.take.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.take.guests_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.take.guests_per_hour_avg"
                                    :compareValue="compareRestaurant.take.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.pickup.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.pickup.guests_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.pickup.guests_per_hour_avg"
                                    :compareValue="compareRestaurant.pickup.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.total.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.total.guests_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.total.guests_per_hour_avg"
                                    :compareValue="compareRestaurant.total.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.total.guests_per_hour_avg | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-restaurant__box load-restaurant__box_table box">
                <table class="load-restaurant__table">
                    <caption class="load-restaurant__table-caption">Блюд в час</caption>
                    <colgroup>
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_head">
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.delivery.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.delivery.dish_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.delivery.dish_per_hour_avg"
                                    :compareValue="compareRestaurant.delivery.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.hall.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.hall.dish_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.hall.dish_per_hour_avg"
                                    :compareValue="compareRestaurant.hall.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.take.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.take.dish_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.take.dish_per_hour_avg"
                                    :compareValue="compareRestaurant.take.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.pickup.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.pickup.dish_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.pickup.dish_per_hour_avg"
                                    :compareValue="compareRestaurant.pickup.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <span class="load-restaurant__table-value">
                                    {{reportRestaurant.total.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-restaurant__table-value"
                                    v-if="compareRestaurant && compareRestaurant.total.dish_per_hour_avg !== 0"
                                    :primaryValue="reportRestaurant.total.dish_per_hour_avg"
                                    :compareValue="compareRestaurant.total.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                {{compareRestaurant.total.dish_per_hour_avg | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-restaurant__box load-restaurant__box_table box">
                <table class="load-restaurant__table">
                    <caption class="load-restaurant__table-caption">Курьеров в час</caption>
                    <colgroup>
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                        <col class="load-restaurant__table-column load-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_head">
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="load-restaurant__table-cell load-restaurant__table-cell_head load-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body">
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-restaurant__table-value">
                                        {{reportRestaurant.delivery.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-restaurant__table-value"
                                        v-if="compareRestaurant && compareRestaurant.delivery.hasOwnProperty('couriers_per_hour_avg') && compareRestaurant.delivery.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportRestaurant.delivery.couriers_per_hour_avg"
                                        :compareValue="compareRestaurant.delivery.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-restaurant__table-value">нет<br> данных</span>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-restaurant__table-value">
                                        {{reportRestaurant.hall.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-restaurant__table-value"
                                        v-if="compareRestaurant && compareRestaurant.hall.hasOwnProperty('couriers_per_hour_avg') && compareRestaurant.hall.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportRestaurant.hall.couriers_per_hour_avg"
                                        :compareValue="compareRestaurant.hall.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-restaurant__table-value">нет<br> данных</span>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.take.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-restaurant__table-value">
                                        {{reportRestaurant.take.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-restaurant__table-value"
                                        v-if="compareRestaurant && compareRestaurant.take.hasOwnProperty('couriers_per_hour_avg') && compareRestaurant.take.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportRestaurant.take.couriers_per_hour_avg"
                                        :compareValue="compareRestaurant.take.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-restaurant__table-value">нет<br> данных</span>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-restaurant__table-value">
                                        {{reportRestaurant.pickup.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-restaurant__table-value"
                                        v-if="compareRestaurant && compareRestaurant.pickup.hasOwnProperty('couriers_per_hour_avg') && compareRestaurant.pickup.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportRestaurant.pickup.couriers_per_hour_avg"
                                        :compareValue="compareRestaurant.pickup.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-restaurant__table-value">нет<br> данных</span>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="reportRestaurant.total.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-restaurant__table-value">
                                        {{reportRestaurant.total.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-restaurant__table-value"
                                        v-if="compareRestaurant && compareRestaurant.total.hasOwnProperty('couriers_per_hour_avg') && compareRestaurant.total.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportRestaurant.total.couriers_per_hour_avg"
                                        :compareValue="compareRestaurant.total.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-restaurant__table-value">нет<br> данных</span>
                            </td>
                        </tr>
                        <tr class="load-restaurant__table-row load-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="compareRestaurant.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareRestaurant.delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="compareRestaurant.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareRestaurant.hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="compareRestaurant.take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareRestaurant.take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="compareRestaurant.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareRestaurant.pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                            <td class="load-restaurant__table-cell load-restaurant__table-cell_body load-restaurant__table-cell_tar">
                                <template v-if="compareRestaurant.total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareRestaurant.total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-restaurant__box load-restaurant__box_charts box">
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_first-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Гостей в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('orders_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_second-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Заказов в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('guests_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_third-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Блюд в час</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('dish_per_hour_avg')" />
                    </figure>
                </div>
                <div class="load-restaurant__chart-wrapper load-restaurant__chart-wrapper_first-of-three">
                    <figure class="load-restaurant__chart">
                        <figcaption class="load-restaurant__chart-title h2">Заказов на курьера</figcaption>
                        <highcharts class="load-restaurant__chart-graph" :options="chartOptions('couriers_per_hour_avg')" />
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { isDaterangeSet, daterangeText, } from "@/helpers/daterange";
    import * as filters from "@/helpers/filters";
    import Difference from "@/components/Difference";

    let totalTitle = "Итоговая эффективность по всем ресторанам";

    export default {
        name: "LoadRestaurant",
        props: {
            id: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            reportLoad: {
                required: true
            },
            compareLoad: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        components: {
            Difference
        },
        computed: {
            ...mapState({
                title: state => state.page.title
            }),
            reportRestaurant() {
                if (this.id === "total") {
                    return this.reportLoad?.total;
                }

                return this.reportLoad?.restaurants?.find(({ id }) => id === this.id);
            },
            compareRestaurant() {
                if (isDaterangeSet(this.compareDaterange)) {
                    if (this.id === "total") {
                        return this.compareLoad?.total;
                    }

                    return this.compareLoad?.restaurants?.find(({ id }) => id === this.id);
                }
            },
            requiredLoads() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.actualReportRestaurantsKey}`]);
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            chartOptions(parameter) {
                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#eb4f3b", "#f4755c", "#fb9780", "#ffb7a5", "#e0121a"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: [daterangeText(this.reportDaterange)],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },
                    tooltip: {
                        pointFormatter() {
                            return `${this.series.name}: ${filters.floatFormat(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return filters.floatFormat(this.y);
                                }
                            }
                        }
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: "Доставка",
                        data: [this.reportRestaurant.delivery?.[parameter] || null]
                    }, {
                        name: "Зал",
                        data: [this.reportRestaurant.hall?.[parameter] || null]
                    }, {
                        name: "С собой",
                        data: [this.reportRestaurant.take?.[parameter] || null]
                    }, {
                        name: "Самовынос",
                        data: [this.reportRestaurant.pickup?.[parameter] || null]
                    }, {
                        name: "Итого",
                        data: [this.reportRestaurant.total?.[parameter] || null]
                    }]
                };

                if (this.modeId === "compareOtherPeriod" && this.compareRestaurant) {
                    options.xAxis.categories.push(daterangeText(this.compareDaterange));
                    options.series[0].data.push(Math.round(this.compareRestaurant.delivery?.[parameter]) || null);
                    options.series[1].data.push(Math.round(this.compareRestaurant.hall?.[parameter]) || null);
                    options.series[2].data.push(Math.round(this.compareRestaurant.take?.[parameter]) || null);
                    options.series[3].data.push(Math.round(this.compareRestaurant.pickup?.[parameter]) || null);
                    options.series[4].data.push(Math.round(this.compareRestaurant.total?.[parameter]) || null);
                }

                return options;
            }
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Ресторан ${this.id}`
                    }
                });
            } else {

                if (this.userRestaurants?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);
                }

                if (this.id === "total" && this.title !== totalTitle) {
                    this.setTitle(totalTitle);

                    this.toggleRestaurantsFilter(true);
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} "${restaurantTitle}"`);
                    }

                    this.toggleRestaurantsFilter(false);
                }
            }
        }
    }
</script>

<style lang="scss">
    .load-restaurant__box {
        padding: 16px;

        & + & {
            margin-top: 15px;
        }

        &_charts {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;

            @include desktop {
                grid-template-columns: repeat(6, 1fr);
            }
        }

        &_table {
            padding-top: 8px;
            overflow-x: auto;
        }
    }
    .load-restaurant__table {
        width: 100%;
    }
    .load-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .load-restaurant__table-column {
        &_compare {
            width: 16.66%;
        }

        &_dynamic {
            width: 16.66%;
        }
    }
    .load-restaurant__table-row {
        &_body {
            &:hover {
                background-color: $gray-form;
            }
        }
    }
    .load-restaurant__table-cell {
        font-size: 20px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_compare {
            font-size: 16px;
            padding: 8px 4px;
            white-space: nowrap;
        }

        &_body {
            padding: 16px 8px;
        }

        &_title {
            font-weight: bold;
        }

        &_centered {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .load-restaurant__table-value {
        display: block;

        & + & {
            margin-left: 5px;
        }
    }
    .load-restaurant__chart-wrapper {
        &_full-width {
            @include desktop {
                grid-column: 1/-1;
            }
        }

        &_first-of-three {
            @include desktop {
                grid-column: 1/3;
            }
        }

        &_second-of-three {
            @include desktop {
                grid-column: 3/5;
            }
        }

        &_third-of-three {
            @include desktop {
                grid-column: 5/7;
            }
        }
    }
    .load-restaurant__chart-title {
        margin-bottom: 10px;
    }
</style>
